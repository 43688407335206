// node.js library that concatenates classes (strings)
// javascipt plugin for creating charts
import Chart from "chart.js";
import { DropzoneArea } from "material-ui-dropzone";
import React from "react";
import Autosuggest from "react-autosuggest";
import "react-confirm-alert/src/react-confirm-alert.css";
import Select from "react-select";
import { confirmAlert } from "react-confirm-alert";

// react plugin used to create charts
// reactstrap components
import Swal from 'sweetalert2';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Row,
} from "reactstrap";
import translator from "../actions/translator";
import userRole from "../actions/userRole";
import { apiActions } from "../apiCall/api.services";
import Header from "../components/Headers/Header.jsx";
import "../layouts/auto.css";
// core components
import { chartOptions, parseOptions } from "../variables/charts.jsx";

class IndexTch extends React.Component {
  state = {
    activeNav: 1,
    chartExample1Data: "data1",
  };

  toggleNavs = (e, index) => {
    e.preventDefault();
    this.setState({
      activeNav: index,
      chartExample1Data:
        this.state.chartExample1Data === "data1" ? "data2" : "data1",
    });
    let wow = () => {};
    wow.bind(this);
    setTimeout(() => wow(), 1000);
    // this.chartReference.update();
  };

  componentWillMount() {
    if (window.Chart) {
      parseOptions(Chart, chartOptions());
    }
  }

  constructor(props) {
    super(props);
    let userAuth = JSON.parse(localStorage.getItem("userAuth"));
    const userLang = localStorage.getItem("userLang");

    var chooseBrand = "";
    var chooseModel = "";
    var chooseProductType = "";
    var chooseCustomer = "";
    var clientType = false;

    if (userLang == 0) {
      chooseBrand = "Choose Brand";
      chooseModel = "Choose Brand";
      chooseProductType = "Choose Product Type";
      chooseCustomer = "Choose Customer";
    } else {
      chooseBrand = "Marka Seçiniz";
      chooseModel = "Model Seçiniz";
      chooseProductType = "Ürün Tipi Seçiniz";
      chooseCustomer = "Müşteri Seçiniz";
    }

    if (userAuth.clientType === "2" || userAuth.clientType === "3") {
      clientType = true;
    }

    this.state = {
      userAuthOptions: {
        accessToken: userAuth.access_token,
        apiKey: userAuth.apiKey,
        clientID: userAuth.clientID,
        roleID: userAuth.roleID,
      },
      redirectedSerialNo: "",
      firstSelectType: "",
      getDeviceTypeOptions: [],
      getDeviceTypeID: [],
      getBrandTypeOptions: [],
      getBrandTypeID: [],
      getModelListOptions: [],
      getDevicesByCustomerIDTypeOptions: [],
      getDevicesByCustomerID: [],
      openOrderCount: "",
      customerOfcustomers: [],
      deviceTypeOptions: [],
      brandOptions: [],
      modelOptions: [],
      serviceTypeOptions: [],
      serialNoOptions: [],
      selectedCustomer: "",
      selectedTypeValue: "",
      selectedBrandValue: "",
      selectedModelValue: "",
      selectedServiceTypeValue: "",
      selectedSerialNoValue: "",
      serialInputValue: "",
      deviceDescriptionValue: "",
      selectedClientID: "",
      noteValue: "",
      isSerialActive: false,
      isCustomerHaveCustomer: clientType,
      isCustomerHaveServiceTypes: false,
      selectedCustomerValue: "",
      selectedCustomerNumberValue: "",
      fullName: "",
      firmName: "",
      closedOrderCount: "",
      canOpenOrder: "",
      deviceTypeValue: "",
      brandTypeValue: "Lütfen Marka Türü Seçiniz",
      devicesByCustomerValue: "",
      modelListValue: "",
      deviceFile: [],
      serialNoSearch: "",
      serialNoSearchActive: false,
      form: {
        serialNumber: "",
        deviceType: "",
        brand: "",
        model: "",
        deviceDescription: "",
        deviceNot: "",
      },
      getForm: {
        customerID: "",
        deviceBrandID: "",
        deviceBrandName: "",
        deviceModelID: "",
        deviceName: "",
        deviceTypeID: "",
        deviceTypeName: "",
        serialNo: "",
        serialNoID: "",
      },
      files: [],
      typeValue: {
        value: "",
        label: chooseProductType,
      },
      brandValue: {
        value: "",
        label: chooseBrand,
      },
      modelValue: {
        value: "",
        label: chooseModel,
      },
      customerValue: {
        value: "",
        label: chooseCustomer,
      },
      pageUrlLocation: "",
      isSubmitForm: false,
      selectedDistrictValue: "",
      selectedProvinceValue:"",
      selectedStreetValue:""
      
    };
    this.scanbarcode= this.scanbarcode.bind(this);
    this.getCustomerSummary = this.getCustomerSummary.bind(this);
    this.getDeviceTypesByCustomer = this.getDeviceTypesByCustomer.bind(this);
    this.getBrandListByDeviceType = this.getBrandListByDeviceType.bind(this);
    this.getModelListByCustomer = this.getModelListByCustomer.bind(this);
    //this.getBrandList = this.getBrandList.bind(this);
    this.postJobOrderAddOrder = this.postJobOrderAddOrder.bind(this);
    this.getOrdersByCustomer = this.getOrdersByCustomer.bind(this);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSerialSubmit = this.handleSerialSubmit.bind(this);
    this.getSearchSerialNoByCustomer = this.getSearchSerialNoByCustomer.bind(
      this
    );
    //this.getServiceTypes = this.getServiceTypes.bind(this);
    this.onSuggestionSelected = this.onSuggestionSelected.bind(this);

    this.getOtherCustomersOfCompany = this.getOtherCustomersOfCompany.bind(
      this
    );
    this.handleSelectSerialNo = this.handleSelectSerialNo.bind(this);

    // this.postUploadViaWeb = this.postUploadViaWeb.bind(this);

    //this.handleSave = this.handleSave.bind(this);
    // this.setTimeout = this.setTimeout.bind(this);
  }

  componentDidMount() {
    const redirectedSerialNo = JSON.parse(localStorage.getItem("seriNoOpen"));
    this.getCustomerSummary();
    const urlLocation = window.location.hostname;
    let checktt = urlLocation === "servis.ttteknoloji.com.tr";
    let checknxt = urlLocation === "servis.next.com.tr";
   if(checktt){

    this.setState({ firstSelectType: "brand" });
    this.setState({ pageUrlLocation: "tt" });
   }
    //this.setState({firstSelectType: 'brand'})
    console.log("AT", urlLocation);

    if (redirectedSerialNo) {
      if (redirectedSerialNo.HasSerialNo === "1") {
        // Tüm üründen gelip serino var ise
        this.getSearchSerialNoByCustomer(redirectedSerialNo.SerialNo);
        localStorage.removeItem("seriNoOpen");
      } else if (redirectedSerialNo.HasSerialNo === "0") {
        // Tüm üründen gelip serino yok ise

        this.getDeviceTypesByCustomer(redirectedSerialNo);
        localStorage.removeItem("seriNoOpen");
      }
    } else {
      //this.getServiceTypes();
      urlLocation === "servis.ttteknoloji.com.tr"
        ? this.getBrandList()
        : this.getDeviceTypesByCustomer();
    }
  }

  handleCustomer = (selectedCustomer) => {
    this.setState({ selectedCustomer });
  };

  handleDeviceType = (selectedTypeValue) => {
    this.setState({ selectedTypeValue });
console.log("selectedTypeValue",selectedTypeValue);
    if (this.state.isSerialActive === false) {
      //this.getModelListByCustomer(selectedTypeValue.value);
      this.state.firstSelectType === "brand" ? this.getModelListByCustomer(selectedTypeValue.value,"") : this.getBrandListByDeviceType(selectedTypeValue.value);
    }
  };

  handleBrand = (selectedBrandValue) => {
    this.setState({ selectedBrandValue });
    if (this.state.isSerialActive === false) {
      this.state.firstSelectType === 'brand' ? this.getDeviceTypesByBrand(selectedBrandValue.value) : this.getModelListByCustomer("",selectedBrandValue.value);
    }
  };

  handleModel = (selectedModelValue) => {
    this.setState({ selectedModelValue });
    if (this.state.isSerialActive === false) {
      this.getDevicesByCustomerID(selectedModelValue.value);
    }
  };

  handleSelectSerialNo = (selectedSerialNoValue) => {

    console.log("selectedSerialNoValue",selectedSerialNoValue);
    this.setState({ selectedSerialNoValue });
  };

  handleSerialNoInput = (e) => {
    this.setState({ serialInputValue: e.target.value });
  };

  handleDeviceDescription = (e) => {
    this.setState({ deviceDescriptionValue: e.target.value });
  };

  handleServiceType = (selectedServiceTypeValue) => {
    this.setState({ selectedServiceTypeValue });
  };

  handleNote = (e) => {
    this.setState({ noteValue: e.target.value });
  };

  handleSave = (files) => {
    const uploadedFiles = Array.from(files);

    /*     setTimeout(() => {
      this.setState({ files: [uploadedFiles[0], uploadedFiles[1], uploadedFiles[2], uploadedFiles[3], uploadedFiles[4], uploadedFiles[5], uploadedFiles[6], uploadedFiles[7], uploadedFiles[8], uploadedFiles[9]] });
      console.log(this.state.files);
    }, 2000); */

    this.setState({ files }, () => {});
  };

  handleSubmit(event) {
    
    this.setState({ isSubmitForm: true });
    event.preventDefault();
    this.state.deviceDescriptionValue !== "" &&
      this.postJobOrderAddOrder(
        this.state.selectedTypeValue.value,
        this.state.selectedBrandValue.value,
        this.state.selectedModelValue.value,
        this.state.selectedSerialNoValue.label,
        this.state.deviceDescriptionValue,
        this.state.noteValue,
        this.state.files,
        this.state.selectedCustomerNumberValue,
        this.state.selectedServiceTypeValue.value
      );

    // this.postUploadViaWeb(this.state.fileUpload);
    //this.props.history.push('/admin/active-orders')
  }

  postJobOrderAddOrder(
    deviceType,
    deviceBrand,
    deviceModel,
    serialNumber,
    deviceDescription,
    deviceNot,
    deviceFile,
    customerOfCustomerID,
    serviceType
  ) {
    let postJobOrderAddOrderPromise = apiActions.postJobOrderAddOrder(
      this.state.userAuthOptions.accessToken,
      this.state.userAuthOptions.clientID,
      this.state.userAuthOptions.apiKey,
      deviceType,
      deviceBrand,
      deviceModel,
      serialNumber,
      deviceDescription,
      deviceNot,
      deviceFile,
      customerOfCustomerID,
      serviceType
    ).then((result)=>{
      this.setState({ isSubmitForm: false });
     console.log("postJobOrderAddOrder",result);
//

// ${result.data.IncomingCargoNumber}

const swalHtml = this.state.pageUrlLocation === "tt" ? `Servis kaydınız gönderilmiştir</br></br>Ürününüzü MNG Kargo 560122545 kodu ile teknik servisimize gönderebilirsiniz.` : 'Servis kaydınız gönderilmiştir'

Swal.fire({
  title: `<strong>Referans No : ${result.data.ReferenceID}</strong>`,
  icon: 'info',
  html: swalHtml,
  allowOutsideClick: false,
  focusConfirm: false,
  
  cancelButtonText:
    'OK',
  cancelButtonAriaLabel: 'OK'
})


    })
  }

  handleSerialSubmit() {
    if (this.state.checknxt)
    {
      this.scanBarcode(this.state.serialInputValue);
    }
    else if (this.state.serialInputValue.length > 0) {
      this.getSearchSerialNoByCustomer(this.state.serialInputValue);
    }
  }
  scanBarcode(serialNo) 
  {
    let currentComponent = this;
    let getserialNos= apiActions.scanBarcode(
      this.state.userAuthOptions.accessToken,
      this.state.userAuthOptions.apiKey,
      serialNo
    );

    let devicesModel = [];
    let devicesBrand = [];
    let devicesTypeName = [];
    let devicesSerialNo = [];
    getserialNos.then(function (res) {
      if (res.data.data.length > 0) {
        res.data.data.map(function (row, index) {
          devicesModel.push({
            value: row.DeviceModelID,
            label: row.DeviceName,
          });

          devicesBrand.push({
            value: row.DeviceBrandID,
            label: row.DeviceBrandName,
          });

          devicesTypeName.push({
            value: row.DeviceTypeID,
            label: row.DeviceTypeName,
          });

          devicesSerialNo.push({
            value: row.SerialNoID,
            label: row.SerialNo,
          });

          if (index == 0) {
            currentComponent.setState({
              selectedBrandValue: {
                value: row.DeviceBrandID,
                label: row.DeviceBrandName,
              },
            });

            currentComponent.setState({
              selectedModelValue: {
                value: row.DeviceModelID,
                label: row.DeviceName,
              },
            });

            currentComponent.setState({
              selectedSerialNoValue: {
                value: row.SerialNoID,
                label: row.SerialNo,
              },
            });

            currentComponent.setState({
              selectedTypeValue: {
                value: row.DeviceTypeID,
                label: row.DeviceTypeName,
              },
            });
          }
        });

        currentComponent.setState({ isSerialActive: true });
        currentComponent.setState({ deviceTypeOptions: devicesTypeName });
        currentComponent.setState({ brandOptions: devicesBrand });
        currentComponent.setState({ modelListOptions: devicesModel });
        currentComponent.setState({ serialNoOptions: devicesSerialNo });
        currentComponent.setState({ serialInputValue: serialNo });
      } else {
        alert(" Böyle bir Seri No Mevcut Değil");
      }
    });
  }
  getSearchSerialNoByCustomer(serialNo) {
    let currentComponent = this;
    let getCustomerSummaryPromise = apiActions.getSearchSerialNoByCustomer(
      this.state.userAuthOptions.accessToken,
      this.state.userAuthOptions.apiKey,
      serialNo
    );

    let devicesModel = [];
    let devicesBrand = [];
    let devicesTypeName = [];
    let devicesSerialNo = [];
    getCustomerSummaryPromise.then(function (res) {
      if (res.data.data.length > 0) {
        res.data.data.map(function (row, index) {
          devicesModel.push({
            value: row.DeviceModelID,
            label: row.DeviceName,
          });

          devicesBrand.push({
            value: row.DeviceBrandID,
            label: row.DeviceBrandName,
          });

          devicesTypeName.push({
            value: row.DeviceTypeID,
            label: row.DeviceTypeName,
          });

          devicesSerialNo.push({
            value: row.SerialNoID,
            label: row.SerialNo,
          });

          if (index == 0) {
            currentComponent.setState({
              selectedBrandValue: {
                value: row.DeviceBrandID,
                label: row.DeviceBrandName,
              },
            });

            currentComponent.setState({
              selectedModelValue: {
                value: row.DeviceModelID,
                label: row.DeviceName,
              },
            });

            currentComponent.setState({
              selectedSerialNoValue: {
                value: row.SerialNoID,
                label: row.SerialNo,
              },
            });

            currentComponent.setState({
              selectedTypeValue: {
                value: row.DeviceTypeID,
                label: row.DeviceTypeName,
              },
            });
          }
        });

        currentComponent.setState({ isSerialActive: true });
        currentComponent.setState({ deviceTypeOptions: devicesTypeName });
        currentComponent.setState({ brandOptions: devicesBrand });
        currentComponent.setState({ modelListOptions: devicesModel });
        currentComponent.setState({ serialNoOptions: devicesSerialNo });
        currentComponent.setState({ serialInputValue: serialNo });
      } else {
        alert(" Böyle bir Seri No Mevcut Değil");
      }
    });
  }

  getCustomerSummary() {
    let currentComponent = this;
    let getCustomerSummaryPromise = apiActions.getCustomerSummary(
      this.state.userAuthOptions.accessToken,
      this.state.userAuthOptions.clientID,
      this.state.userAuthOptions.apiKey
    );
    getCustomerSummaryPromise.then(function (res) {
      currentComponent.setState({
        openOrderCount: res.data.OpenOrderCount,
        closedOrderCount: res.data.ClosedOrderCount,
        fullName: res.data.FullName,
        canOpenOrder: res.data.CanOpenOrder,
      });
    });
  }

  getModelListByCustomer(typeId,brandId) {

    const currentComponent = this;
    console.log("type", currentComponent.state.selectedTypeValue.value);
    console.log("brand", currentComponent.state.selectedBrandValue.value);
    
    let arr = [];
    const getModelListByCustomerPromise = apiActions.getModelListByCustomer(
      currentComponent.state.userAuthOptions.accessToken,
      currentComponent.state.userAuthOptions.clientID,
      currentComponent.state.userAuthOptions.apiKey,
      this.state.firstSelectType === "brand" ? typeId : "",
      this.state.firstSelectType === "brand" ? "" : brandId,
    );
    getModelListByCustomerPromise.then((res) => {

      console.log("res", res.data.data);

      res.data.data.map(function (row, index) {
        arr.push({
          value: row.DeviceModelID,
          label: row.DeviceName,
        });

        if (index == 0) {
          currentComponent.setState({
            selectedModelValue: {
              value: row.DeviceModelID,
              label: row.DeviceName,
            },
          });

          //currentComponent.getDevicesByCustomerID(row.DeviceModelID);
        }
      });
arr.length === 0 && currentComponent.setState({ selectedModelValue: ""});
      currentComponent.setState({ modelOptions: arr});
      currentComponent.setState({ serialNoOptions: [] });
      currentComponent.setState({ selectedSerialNoValue: "" });
    });
  }

  getBrandListByDeviceType(deviceTypeId) {
    console.log("deviceTypeId", deviceTypeId);
    const currentComponent = this;
    let arr = [];
    const getBrandListByDeviceTypePromise = apiActions.getBrandListByDeviceType(
      this.state.userAuthOptions.accessToken,
      this.state.userAuthOptions.clientID,
      this.state.userAuthOptions.apiKey,
      deviceTypeId
    );
    getBrandListByDeviceTypePromise.then(function (res) {
      console.log("brand return", res.data.data);
      res.data.data.map(function (row, index) {
        arr.push({
          value: row.DeviceBrandID,
          label: row.DeviceBrandName,
        });

        if (index == 0) {
          currentComponent.setState({
            selectedBrandValue: {
              value: row.DeviceBrandID,
              label: row.DeviceBrandName,
            },
          });
          currentComponent.getModelListByCustomer("",row.DeviceBrandID);
        }
      });

      currentComponent.setState({ brandOptions: arr });
      currentComponent.setState({ modelOptions: [] });
      currentComponent.setState({ serialNoOptions: [] });
      currentComponent.setState({ selectedModelValue: "" });
      currentComponent.setState({ selectedSerialNoValue: "" });
    });
  }

  getBrandList(noSerialData) {
    const currentComponent = this;
    let arr = [];
    const getBrandListPromise = apiActions.getBrandList(
      this.state.userAuthOptions.accessToken,
      this.state.userAuthOptions.clientID,
      this.state.userAuthOptions.apiKey
    );
    getBrandListPromise.then(function (res) {

      console.log("brands",res.data.data)
      res.data.data.map(function (row, index) {
        arr.push({
          value: row.DeviceBrandID,
          label: row.DeviceBrandName,
        });

      if (index == 0) {
        currentComponent.setState({
          selectedBrandValue: {
            value: row.DeviceBrandID,
            label: row.DeviceBrandName,
          },
        });
        currentComponent.getDeviceTypesByBrand(row.DeviceBrandID);
      }



    });
    currentComponent.setState({ brandOptions: arr });
  });
}
  

  /*
  getServiceTypes() {
    const currentComponent = this;
    let arr = [];
    const getServiceTypesPromise = apiActions.getPageDefinitions(
      this.state.userAuthOptions.accessToken,

      this.state.userAuthOptions.apiKey,
    );
    getServiceTypesPromise.then(function (res) {
      res.data.data.map(function (row, index) {
        arr.push({
          value: row.Value,
          label: row.FieldName,
        });
      });

      currentComponent.setState({ serviceTypeOptions: arr });
      currentComponent.setState({ isCustomerHaveServiceTypes: true });
    });
  }
*/
  getDeviceTypesByCustomer(noSerialData) {
    const currentComponent = this;
    let arr = [];
    const getDeviceTypesByCustomerPromise = apiActions.getDeviceTypesByCustomer(
      this.state.userAuthOptions.accessToken,
      this.state.userAuthOptions.clientID,
      this.state.userAuthOptions.apiKey
    );
    getDeviceTypesByCustomerPromise.then(function (res) {
      console.log("respp", res.data.data);
      res.data.data.map(function (row, index) {
        arr.push({
          value: row.DeviceTypeID,
          label: row.DeviceTypeName,
        });

        if (index == 0) {
          if (noSerialData) {
            currentComponent.setState({
              selectedTypeValue: {
                value: noSerialData.DeviceTypeID,
                label: noSerialData.DeviceTypeName,
              },
            });

            currentComponent.getBrandListByDeviceType(
              noSerialData.DeviceTypeID
            );
          } else {
            currentComponent.setState({
              selectedTypeValue: {
                value: row.DeviceTypeID,
                label: row.DeviceTypeName,
              },
            });
            currentComponent.getBrandListByDeviceType(currentComponent.state.selectedTypeValue.value);
          }
        }
      });

      currentComponent.setState({ deviceTypeOptions: arr });
    });
  }

  
	getDeviceTypesByBrand(brandID) {
		const currentComponent = this;
		let arr = [];
		const getDeviceTypesByCustomerPromise = apiActions.getDeviceTypesByCustomer(
			this.state.userAuthOptions.accessToken,
			this.state.userAuthOptions.clientID,
			this.state.userAuthOptions.apiKey,
			brandID
		);
		getDeviceTypesByCustomerPromise.then(function (res) {
      console.log("deviceList",res.data);
			res.data.data.map(function (row, index) {
				arr.push({
					value: row.DeviceTypeID,
					label: row.DeviceTypeName
        });
        

        if (index == 0) {
        
            currentComponent.setState({
              selectedTypeValue: {
                value: row.DeviceTypeID,
                label: row.DeviceTypeName,
              },
            });
            currentComponent.getModelListByCustomer(row.DeviceTypeID,"");
          
        }

			});

			currentComponent.setState({ deviceTypeOptions: arr });
		});
	}


  // Serial No buradan çekiliyor
  getDevicesByCustomerID(modelID) {
    const currentComponent = this;
    let arr = [];
    const getDevicesByCustomerIDPromise = apiActions.getDevicesByCustomerID(
      this.state.userAuthOptions.accessToken,
      this.state.userAuthOptions.clientID,
      this.state.userAuthOptions.apiKey,
      modelID
    );
    getDevicesByCustomerIDPromise.then(function (res) {
      res.data.data.map(function (row, index) {
        arr.push({
          value: row.SerialNo,
          label: row.SerialNo,
        });

        if (index == 0) {
          currentComponent.setState({
            selectedSerialNoValue: {
              value: row.SerialNo,
              label: row.SerialNo,
            },
          });
        }
      });
      currentComponent.setState({ serialNoOptions: arr });
    });
  }

  getOtherCustomersOfCompany(term) {
    const currentComponent = this;

    let arr = [];

    const getOtherCustomersOfCompanyPromise = apiActions.getOtherCustomersOfCompanyByCustomer(
      this.state.userAuthOptions.accessToken,
      this.state.userAuthOptions.clientID,
      this.state.userAuthOptions.apiKey,
      term
    );
    getOtherCustomersOfCompanyPromise.then(function (res) {
      res.data.map(function (row, index) {
        arr.push({ value: row.CustomerID, label: row.FullName });
      });

      currentComponent.setState({
        customerOfcustomers: arr,
      });
    });
  }

  getOrdersByCustomer() {
    const currentComponent = this;
    const getOrdersByCustomersPromise = apiActions.getOrdersByCustomer(
      this.state.userAuthOptions.accessToken,
      this.state.userAuthOptions.clientID,
      this.state.userAuthOptions.apiKey
    );
    getOrdersByCustomersPromise.then(function (res) {
      currentComponent.setState({
        getDevicesByCustomerIDTypeOptions: res.data.data,
      });
    });
  }
  /*
    postUploadViaWeb(uploadFiles) {
      const currentComponent = this;
      let postUploadViaWebPromise = apiActions.postUploadViaWeb(this.state.userAuthOptions.accessToken, this.state.userAuthOptions.apiKey, uploadFiles);
      postUploadViaWebPromise.then(function (res) {
        console.log(res);
      });
    }
  */

  onChange = (event, { newValue }) => {
    this.setState({
      selectedCustomerValue: newValue,
    });
  };

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.getOtherCustomersOfCompany(value);
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {};

  getSuggestionValue = (suggestion) => suggestion.label;
  // getSuggestionValue = customerOfcustomers => customerOfcustomers.label;

  // Use your imagination to render suggestions.
  renderSuggestion = (suggestion) => <div>{suggestion.label}</div>;

  onSuggestionSelected(event, { suggestion }) {
    this.setState({ selectedCustomerNumberValue: suggestion.value });
  }

  CustomerOfCustomerDisplay = (props) => {
    if (props.isOK == true) {
      return (
        <FormGroup>
          <label className="form-control-label" htmlFor="input-username">
            {translator.customer}
          </label>

          <Autosuggest
            suggestions={props.suggestions}
            onSuggestionsFetchRequested={props.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={props.onSuggestionsClearRequested}
            getSuggestionValue={props.getSuggestionValue}
            renderSuggestion={props.renderSuggestion}
            inputProps={props.inputProps}
            onSuggestionSelected={props.onSuggestionSelected}
          />
        </FormGroup>
      );
    } else {
      return null;
    }
  };

  ServiceTypes = (props) => {
    if (props.isOK == true) {
      return (
        <FormGroup>
          <label className="form-control-label" htmlFor="input-username">
            {translator.serviceTypes}
          </label>

          <Select
            placeholder={props.placeholder}
            onChange={props.onChange}
            options={props.options}
            value={props.value}
          />
        </FormGroup>
      );
    } else {
      return null;
    }
  };

  render() {
    const {
      form,
      getForm,
      serialNoSearchActive,
      deviceTypeOptions,
      brandOptions,
      modelOptions,
      getDevicesByCustomerIDTypeOptions,
      isCustomerHaveCustomer,
      selectedCustomer,
      customerOfcustomers,
      serialInputValue,
      selectedCustomerValue,
      getgetSuggestionStateValue,
      serialNoOptions,
      selectedServiceTypeValue,
      isCustomerHaveServiceTypes,
      serviceTypeOptions,
    } = this.state;

    const {
      onSuggestionsFetchRequested,
      onSuggestionsClearRequested,
      getSuggestionValue,
      renderSuggestion,
      onSuggestionSelected,
      handleServiceType,
    } = this;

    const { CustomerOfCustomerDisplay, ServiceTypes } = this;

    const userAuthUpdate = JSON.parse(localStorage.getItem("userAuth"));
    if (this.state.canOpenOrder === "0") {
      window.location.replace("/admin/active-orders");
    }

    if (userRole().openNewOrder === false) {
      window.location.replace("/admin/active-orders");
    }

    if (userRole().canSeeDeviceDetails === false) {
      // göremesin burayaları
    }

    if (userAuthUpdate.isCustomerInfoUpdated === "1") {
      window.location.replace("/admin/user-profile");
    }

    if (localStorage.getItem("mfaActive") === "1") {
      window.location.replace("/admin/mfa");
    }


    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: translator.enterCustomerName,
      value: selectedCustomerValue,
      onChange: this.onChange,
    };

    return (
      <>
        <Header />
        {/* Page content */}

        <Container className="mt--7" fluid>
          <Row className="mt-5">
            <Col className="order-xl-1" xl="12">
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="8">
                      <h3 className="mb-0">{translator.supportRequest}</h3>
                      <h6 className="heading-small text-muted mb-4">
                       
                        {this.state.pageUrlLocation === "tt" ? <span style={{color:'#f5365c'}}>Servis Kaydını Açtıktan Sonra Sistemin Verdiği Referans Numarasını Ürününüzün İçeriğine Mutlaka Not Olarak Yazınız!!!</span> : translator.requiredFields }
                      </h6>
                    </Col>
                  </Row>
                </CardHeader>
                <Form id="open-order-form" onSubmit={this.handleSubmit}>
                  <CardBody>
                    <div className="pl-lg-4">
                      <Row>
                        <Col lg="12">
                          <CustomerOfCustomerDisplay
                            isOK={isCustomerHaveCustomer}
                            suggestions={customerOfcustomers}
                            onSuggestionsFetchRequested={
                              onSuggestionsFetchRequested
                            }
                            onSuggestionsClearRequested={
                              onSuggestionsClearRequested
                            }
                            getSuggestionValue={getSuggestionValue}
                            renderSuggestion={renderSuggestion}
                            inputProps={inputProps}
                            onSuggestionSelected={onSuggestionSelected}
                          />
                          {/* <ServiceTypes
														isOK={isCustomerHaveServiceTypes}
														onChange={handleServiceType}
														options={serviceTypeOptions}
														value={selectedServiceTypeValue}
														placeholder={translator.selectServiceType}
													/> */}

                          <FormGroup>
                            <Row>
                              <Col lg="6">
                                <label
                                  className="form-control-label"
                                  htmlFor="serialNoSearch"
                                >
                                  {translator.serialNo}
                                </label>
                                <Input
                                  type="text"
                                  id="serialNoSearch"
                                  name="serialNoSearch"
                                  className="form-control-alternative"
                                  placeholder={translator.enterSerialNumber}
                                  value={this.state.serialInputValue}
                                  onChange={(e) => this.handleSerialNoInput(e)}
                                  disabled
                                />
                              </Col>
                              <Col lg="6" style={{ marginTop: "28px" }}>
                                <Button
                                  color="primary"
                                  size="lg"
                                  type="submit"
                                  onClick={(e) => this.handleSerialSubmit(e)}
                                >
                                  {translator.search}
                                </Button>
                              </Col>
                            </Row>
                          </FormGroup>

                          {this.state.firstSelectType === "brand" ? (
                            <>
                             {/* Markayla başlama burada */}
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-brandTypeValueID"
                                >
                                  {translator.brand}
                                </label>

                                <Select
                                  id="brandTypeValueID"
                                  name="brand"
                                  onChange={this.handleBrand}
                                  options={brandOptions}
                                  value={this.state.selectedBrandValue}
                                  placeholder={translator.selectBrand}
                                />
                              </FormGroup>

                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="deviceTypeValueID"
                                >
                                  {translator.productType}
                                </label>

                                <Select
                                  id="deviceTypeValueID"
                                  name="deviceType"
                                  onChange={this.handleDeviceType}
                                  options={deviceTypeOptions}
                                  value={this.state.selectedTypeValue}
                                  placeholder={translator.selectDeviceType}
                                />
                              </FormGroup>
                            </>
                          ) : (
                            <>
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="deviceTypeValueID"
                                >
                                  {translator.productType}
                                </label>

                                <Select
                                  id="deviceTypeValueID"
                                  name="deviceType"
                                  onChange={this.handleDeviceType}
                                  options={deviceTypeOptions}
                                  value={this.state.selectedTypeValue}
                                  placeholder={translator.selectDeviceType}
                                />
                              </FormGroup>
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-brandTypeValueID"
                                >
                                  {translator.brand}
                                </label>

                                <Select
                                  id="brandTypeValueID"
                                  name="brand"
                                  onChange={this.handleBrand}
                                  options={brandOptions}
                                  value={this.state.selectedBrandValue}
                                  placeholder={translator.selectBrand}
                                />
                              </FormGroup>
                            </>
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="modelListValueID"
                            >
                              {translator.model}
                            </label>

                            <Select
                              id="modelListValueID"
                              name="model"
                              onChange={this.handleModel}
                              options={modelOptions}
                              value={this.state.selectedModelValue}
                              placeholder={translator.selectModel}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="serialNumber"
                            >
                              {translator.serialNo}
                            </label>

                            <Select
                              id="serialNumber"
                              name="serialNumber"
                              onChange={this.handleSelectSerialNo}
                              options={serialNoOptions}
                              value={this.state.selectedSerialNoValue}
                              placeholder={translator.selectSerialNo}
                            />
                          </FormGroup>
                        </Col>

                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="deviceDescription"
                            >
                              {translator.faultDescription}
                            </label>
                            <Input
                              type="textarea"
                              rows="2"
                              id="deviceDescription"
                              name="deviceDescription"
                              className="form-control-alternative"
                              placeholder={translator.enterFaultDescription}
                              required
                             
                              onChange={this.handleDeviceDescription}
                            />
                            <FormFeedback tooltip>
                              Oh noes! that name is already taken
                            </FormFeedback>
                          </FormGroup>
                        </Col>

                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="deviceNot"
                            >
                              {translator.note}
                            </label>
                            <Input
                              type="textarea"
                              rows="2"
                              id="deviceNot"
                              name="deviceNot"
                              className="form-control-alternative"
                              placeholder={translator.enterNote}
                              onChange={this.handleNote}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <FormGroup>
                            <label
                              className="form-control-label"
                              htmlFor="input-username"
                            >
                              {translator.files}
                            </label>
                            <DropzoneArea
                              className="form-control-alternative"
                              maxFileSize={5000000}
                              filesLimit={10}
                              acceptedFiles={[
                                "image/jpeg",
                                "image/png",
                                "image/bmp",
                              ]}
                              showAlerts={true}
                              dropzoneText={
                                translator.dragAndDropAnImageFileHereOrClick
                              }
                              onChange={this.handleSave}
                            />
                          </FormGroup>
                        </Col>
                        <Col lg="12">
                          <Button
                            color="primary"
                            size="lg"
                            type="submit"
                            disabled={this.state.isSubmitForm}
                            //onClick={this.handleSubmit}
                          >
                            {translator.send}
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </CardBody>
                </Form>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default IndexTch;


