import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { apiActions } from "../../apiCall/api.services";
import {
    Button,
    Card,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Col,
} from "reactstrap";

class MfaPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mfacode: ""
        };
    }

    handleChange(event) {
        this.setState({ [event.target.name]: event.target.value });
    }

    render() {

        return (<>
            <Col lg="5" md="7" style={{ marginLeft: "auto", marginRight: "auto", top: 140 }}>
                <Card className="bg-secondary shadow border-0">
                    <CardBody className="px-lg-5 py-lg-5">
                        <div className="text-center text-muted mb-4">
                            img
                        </div>
                        <Form role="form">
                            <FormGroup className="mb-3">
                                <InputGroup className="input-group-alternative">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText>
                                            <i className="ni ni-email-83" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        placeholder="Tek Kullanımlık Şifre"
                                        id="mfacode"
                                        name="mfacode"
                                        type="text"
                                        required
                                        onChange={this.handleChange}
                                    />
                                </InputGroup>
                            </FormGroup>

                            <div className="text-center">
                                <Button className="my-4" color="primary" type="submit">
                                    Giriş
                                </Button>
                            </div>
                        </Form>
                    </CardBody>
                </Card>
            </Col>
        </>);


    };
}

export default MfaPage;