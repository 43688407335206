import React from 'react';

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Container,
  Row,
  Button,
  Col,
} from 'reactstrap';
import { Digital } from 'react-activity';
import 'react-activity/dist/react-activity.css';
import Modal from 'react-modal';
import ClaimHeader from '../../components/Headers/ClaimHeader.jsx';
import { apiActions } from '../../apiCall/api.services';
import MUIDataTable from 'mui-datatables';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import userRole from '../../actions/userRole';
import translator from '../../actions/translator';
import { logoutUser } from '../../actions/authActions';
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    border: '0',
    backgroundColor: 'transparent',
    zIndex: '130',
  },
};

class ClaimList extends React.Component {
  constructor(props) {
    super(props);
    let userAuth = JSON.parse(localStorage.getItem('userAuth'));
    this.state = {
      testVal: '',
      userAuthOptions: {
        accessToken: userAuth.access_token,
        apiKey: userAuth.apiKey,
        clientID: userAuth.clientID,
      },
      getClaimOptions: [],
      claimTypeZero: 0,
      claimTypeOne: 0,
      claimTypeTwo: 0,
      claimTypeThree: 0,
      loadingAnimate: true,
      modalIsOpen: true,
    };
    this.getClaimDetails = this.getClaimDetails.bind(this);
    this.getActiveClaims = this.getActiveClaims.bind(this);
  }

  componentDidMount() {
    this.getActiveClaims();
  }

  getClaimDetails(getClaimID) {
    localStorage.setItem('claimDetails', JSON.stringify(getClaimID));
    this.props.history.push('/admin/claim-details');
  }

  getActiveClaims() {
    let currentComponent = this;
    apiActions.getClaimList(
      this.state.userAuthOptions.accessToken,
    ).then((res) => {
      var statusCorrection = res.data.data;
      statusCorrection.map(function(obj, index) {
        if (obj.Status == 0) {
          obj.Status = translator.notEvaluated;

          currentComponent.setState({
            claimTypeZero: currentComponent.state.claimTypeZero + 1,
          });
        } else if (obj.Status == 1) {
          obj.Status = translator.finalized;
          currentComponent.setState({
            claimTypeOne: currentComponent.state.claimTypeOne + 1,
          });
        } else if (obj.Status == 2) {
          obj.Status = translator.partialRejection;
          currentComponent.setState({
            claimTypeTwo: currentComponent.state.claimTypeTwo + 1,
          });
        } else if (obj.Status == 3) {
          obj.Status = translator.underAppraisal;
          currentComponent.setState({
            claimTypeThree: currentComponent.state.claimTypeThree + 1,
          });
        }
      });

      res.data.data.map((item) => {
        item.InvoiceDate = item.InvoiceDate ? Intl.DateTimeFormat('tr-TR', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit'}).format(new Date(item.InvoiceDate)) : 'Tarih Bulunamadı';
        item.SubmitDate = item.SubmitDate ? Intl.DateTimeFormat('tr-TR', {year: 'numeric', month: '2-digit',day: '2-digit', hour: '2-digit', minute: '2-digit'}).format(Date.parse(item.SubmitDate)) : 'Tarih Bulunamadı';
      });

      currentComponent.setState({ getClaimOptions: res.data.data });
      currentComponent.setState({ loadingAnimate: false });
      currentComponent.setState({ modalIsOpen: false });
    }).catch(error=>{

      console.log("error",error.response.status);
      error.response.status == 401 && logoutUser();
            })
  }

  render() {
    const { getClaimOptions } = this.state;

    const columns = [
      {
        name: 'ClaimRefID',
        field: 'ClaimRefID',
        label: translator.claimID,
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'Technician',
        field: 'Technician',
        label: translator.technician,
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'InvoiceNumber',
        field: 'InvoiceNumber',
        label: translator.invoiceNumber,
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'InvoiceDate',
        field: 'InvoiceDate',
        label: translator.invoiceDate,
        options: {
          filter: true,
          sort: true,
        },
      },

      {
        name: 'CompanyName',
        field: 'CompanyName',
        label: translator.companyName,
        options: {
          filter: true,
          sort: true,
        },
      },

      {
        name: 'SubmitDate',
        field: 'SubmitDate',
        label: translator.submitDate,
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'GeneralTotal',
        field: 'GeneralTotal',
        label: translator.grandTotal,
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'Status',
        field: 'Status',
        label: translator.status,
        options: {
          filter: true,
          sort: true,
        },
      },
      {
        name: 'ClaimID',
        field: 'ClaimID',
        label: '',
        options: {
          display: true,
          customBodyRender: (value, tableMeta, updateValue) => (
            <Button
              className="tableButtonStyle"
              color="primary"
              value={value}
              type="button"
            >
              {translator.seeClaim}
            </Button>
          ),
        },
      },
    ];

    const options = {
      filterType: 'dropdown',
      onCellClick: (cellIndex, rowIndex) => {
        if (rowIndex.colIndex === 8) {
          if (cellIndex.props.value !== undefined) {
            this.getClaimDetails(cellIndex.props.value);
          }
        }
      },
    };

    const userAuthUpdate = JSON.parse(localStorage.getItem('userAuth'));
    if (userAuthUpdate.isCustomerInfoUpdated === '1') {
      window.location.replace('/admin/user-profile');
    }

    if (localStorage.getItem("mfaActive") === "1") {
      window.location.replace("/admin/mfa");
    }


    return (
      <>
        <Modal
          isOpen={this.state.modalIsOpen}
          //onAfterOpen={this.afterOpenModal}
          // onRequestClose={this.closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <Digital
            animating={this.state.loadingAnimate}
            color="#52BFE8"
            size={64}
            speed={1}
          />
        </Modal>
        <div className="header bg-gradient-info pb-8 pt-5 pt-md-8">
          <Container fluid>
            <div className="header-body">
              {/* Card stats */}
              <Row>
                <Col lg="3" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            {translator.waitingAppraisalCount}
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {this.state.claimTypeZero}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-danger text-white rounded-circle shadow">
                            <i className="fas fa-pause" />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="3" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            {translator.finalizedCount}
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {this.state.claimTypeOne}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                            <i className="fas fa-check" />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="3" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            {translator.partialRejectionCount}
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {this.state.claimTypeTwo}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                            <i className="fas fa-star-half-alt" />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg="3" xl="3">
                  <Card className="card-stats mb-4 mb-xl-0">
                    <CardBody>
                      <Row>
                        <div className="col">
                          <CardTitle
                            tag="h5"
                            className="text-uppercase text-muted mb-0"
                          >
                            {translator.underAppraisalCount}
                          </CardTitle>
                          <span className="h2 font-weight-bold mb-0">
                            {this.state.claimTypeThree}
                          </span>
                        </div>
                        <Col className="col-auto">
                          <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                            <i className="fas fa-play" />
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </div>
          </Container>
        </div>
        {/* Page content */}
        <Container className="mt--7" fluid>
          {/* Table */}
          <Row>
            <div className="col">
              <Card className="shadow">
                <MuiThemeProvider theme={getMuiTheme()}>
                  <MUIDataTable
                    title={translator.claimList}
                    data={getClaimOptions}
                    columns={columns}
                    options={options}
                  />
                </MuiThemeProvider>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

const getMuiTheme = () => createMuiTheme({
  overrides: {
    MUIDataTableHeadCell: {
      root: {
        textAlign: 'center',
        padding: '5px',
        fontSize: '1.1vw'
      }
    },
    MUIDataTableBodyCell: {
      root: {
        textAlign: 'center',
        padding: '20px 5px',
        fontSize: '1vw'
      }
    }
  }
})

export default ClaimList;
