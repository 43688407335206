

import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import MUIDataTable from 'mui-datatables';
import React from 'react';
import { Digital } from 'react-activity';
import 'react-activity/dist/react-activity.css';
import Modal from 'react-modal';
// reactstrap components
import { Button, Container, Row } from 'reactstrap';
import translator from '../../actions/translator';
import userRole from '../../actions/userRole';
import { apiActions } from '../../apiCall/api.services';
// core components
import Header from '../../components/Headers/Header.jsx';
import { logoutUser } from '../../actions/authActions';

const customStyles = {
	content: {
		top: '50%',
		left: '50%',
		right: 'auto',
		bottom: 'auto',
		marginRight: '-50%',
		transform: 'translate(-50%, -50%)',
		border: '0',
		backgroundColor: 'transparent',
		zIndex: '130'
	}
};

class AllProducts extends React.Component {
	constructor(props) {
		super(props);
		let userAuth = JSON.parse(localStorage.getItem('userAuth'));
		this.state = {
			testVal: '',
			userAuthOptions: {
				accessToken: userAuth.access_token,
				apiKey: userAuth.apiKey,
				clientID: userAuth.clientID,
				roleID: userAuth.roleID
			},
			getActiveOrdersOptions: [],
			loadingAnimate: true,
			modalIsOpen: true,
			tableImageVisibility: false
		};

		this.getActiveOrders = this.getActiveOrders.bind(this);
		this.getSeriNoOpen = this.getSeriNoOpen.bind(this);
		this.removeSeriNoOpen = this.removeSeriNoOpen.bind(this);
		this.handleMouseOver = this.handleMouseOver.bind(this);
		this.handleMouseLeave = this.handleMouseLeave.bind(this);
	}

	componentDidMount() {
		this.getActiveOrders();
		this.removeSeriNoOpen();
	}

	getActiveOrders() {
		let currentComponent = this;
		apiActions
			.getDevicesOfCustomer(
				this.state.userAuthOptions.accessToken,
				this.state.userAuthOptions.clientID,
				this.state.userAuthOptions.apiKey
			)
			.then((res) => {
				console.log("allDataRes", res.data.data);
				currentComponent.setState({
					getActiveOrdersOptions: this.getPictureLocationAndDeviceName(res.data.data)
				});
				currentComponent.setState({ loadingAnimate: false });
				currentComponent.setState({ modalIsOpen: false });
			}).catch(error => {

				console.log("error", error.response.status);
				error.response.status == 401 && logoutUser();
			})
	}

	getSeriNoOpen(getSeriNoID) {
		localStorage.setItem('seriNoOpen', JSON.stringify(getSeriNoID));
		this.props.history.push('/admin/dashboard');
	}

	removeSeriNoOpen() {
		localStorage.removeItem('seriNoOpen');
	}

	handleMouseOver(value) {
		if (value.pictureUrl) {
			let parentDiv = document.getElementById(`${value.deviceName}`);
			let img = document.createElement('IMG');
			img.id = value.pictureUrl;
			img.src = value.pictureUrl;
			img.style.maxWidth = '150px';
			img.style.maxHeight = '150px';
			parentDiv.appendChild(img);
		}
	}

	handleMouseLeave(value) {
		if (value.pictureUrl) {
			let img = document.getElementById(`${value.pictureUrl}`);
			img.remove();
		}
	}

	getPictureLocationAndDeviceName(data) {
		let tableData = [];

		data.map((item, index) => {


			let tableDataItem = {
				SerialNoID: item.SerialNoID,
				DeviceTypeName: item.DeviceTypeName,
				Device: {
					deviceName: item.DeviceName,
					pictureUrl: item.PictureLocation
				},
				DeviceName: item.DeviceName,
				BrandName: item.BrandName,
				SerialNo: item.SerialNo,
				Customer: item.Customer,
				BrandID: item.BrandID,
				DeviceModelID: item.DeviceModelID,
				DeviceTypeID: item.DeviceTypeID,
				HasSerialNo: item.HasSerialNo
			};
			tableData.push(tableDataItem);
		});
		return tableData;
	}

	render() {
		const { getActiveOrdersOptions } = this.state;
		const columns = [
			{
				name: 'SerialNoID',
				label: translator.productID,
				options: {
					filter: true,
					sort: true
				}
			},
			{
				name: 'DeviceTypeName',
				label: translator.deviceType,
				options: {
					filter: true,
					sort: true
				}
			},
			{
				name: 'BrandName',
				label: translator.brand,
				options: {
					filter: true,
					sort: true
				}
			},
			{
				name: 'Device',
				label: translator.model,
				options: {
					filter: true,
					sort: true,
					customBodyRender: (value, tableMeta, updateValue) => (
						<div
							id={value.deviceName}

						>
							{value.deviceName}
							{value.pictureUrl && (
								<>
									<br />
									<a href={value.pictureUrl} target='_blank' >

										<div style={{ backgroundImage: `url(${value.pictureUrl})`, backgroundSize: 'cover', width: 50, height: 50, margin: 'auto' }}></div>
									</a>
								</>
							)}
						</div>
					)
				}
			},
			{
				name: 'SerialNo',
				label: translator.serialNo,
				options: {
					filter: true,
					sort: true
				}
			},
			{
				name: 'Customer',
				label: translator.Store,
				options: {
					filter: true,
					sort: true
				}
			},
			{
				name: 'DeviceName',
				label: translator.DeviceName,
				options: {
					filter: true,
					sort: true,
					display: "hidden"
				}
			},
			{
				name: 'SerialNo',
				field: 'SerialNo',
				label: translator.openWorkOrder,
				options: {
					filter: true,
					sort: true,
					customBodyRender: (value, tableMeta, updateValue) => (
						<Button
							className='tableButtonStyle'
							color='primary'
							value={value}
							type='button'>
							{translator.openWorkOrder}
						</Button>
					)
				}
			}
		];



		/*


		<div
							id={value.deviceName}
							onMouseOver={() => this.handleMouseOver(value)}
							onMouseOut={() => this.handleMouseLeave(value)}
							>
							<a href={value.pictureUrl} target='_blank'>
								{value.deviceName}
							</a>
							<br />
						</div>


						*/

		const options = {
			filterType: 'dropdown',
			onCellClick: (cellIndex, rowIndex) => {
				if (rowIndex.colIndex === 7) {
					if (cellIndex.props.value !== undefined) {
						this.getSeriNoOpen(getActiveOrdersOptions[rowIndex.dataIndex]);
					}
				}
			}
		};

		const userAuthUpdate = JSON.parse(localStorage.getItem('userAuth'));
		if (userAuthUpdate.isCustomerInfoUpdated === '1') {
			window.location.replace('/admin/user-profile');
		}

		if (localStorage.getItem("mfaActive") === "1") {
			window.location.replace("/admin/mfa");
		  }  

		// Delete Work Order by Role TC
		if (userRole().workOrderButton === false) {
			columns.splice(6, 1);
		}

		return (
			<>
				<Modal
					isOpen={this.state.modalIsOpen}
					//onAfterOpen={this.afterOpenModal}
					// onRequestClose={this.closeModal}
					style={customStyles}
					contentLabel='Example Modal'>
					<Digital
						animating={this.state.loadingAnimate}
						color='#52BFE8'
						size={64}
						speed={1}
					/>
				</Modal>
				<Header />
				{/* Page content */}
				<Container className='mt--7' fluid>
					{/* Table */}
					<Row>
						<div className='col'>
							<MuiThemeProvider theme={getMuiTheme()}>
								<MUIDataTable
									title={translator.allProducts}
									data={getActiveOrdersOptions}
									columns={columns}
									options={options}
								/>
							</MuiThemeProvider>
						</div>
					</Row>
					<Row></Row>
				</Container>
			</>
		);
	}
}

const getMuiTheme = () =>
	createMuiTheme({
		overrides: {
			MUIDataTableHeadCell: {
				root: {
					textAlign: 'center',
					padding: '5px',
					fontSize: '1.1vw'
				}
			},
			MUIDataTableBodyCell: {
				root: {
					textAlign: 'center',
					padding: '20px 5px',
					fontSize: '1vw'
				}
			}
		}
	});

export default AllProducts;
